@import "normalize.css/normalize";

// settings
@import "settings/variables";
@import "settings/colors";

// mixins
@import "mixins/index";

@import "base/fonts";
@import "base/responsive";

:root {
  --vh: 100vh;
  --container-width: 1036px;
  --width-base: #{$laptop};
  --width-current: #{$laptop} * 1px;
  --width-multiplier: var(--width-current) / var(--width-base);
  --font-family-base: "Roboto";

  @include laptop {
    --width-current: 100vw;
  }

  @include devices {
    --width-base: #{$mobile};
    --width-current: 100vmin;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// Не вызывает performance issues https://stackoverflow.com/questions/37444066/is-applying-a-css-transition-on-all-elements-a-performance-concern
// Исключения добавляются через :not()
html {
  position: relative;

  min-height: 100vh;
  box-sizing: border-box;
  font-family: var(--font-family-base);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  scroll-behavior: smooth;


  &.cursor-hide,
  &.cursor-hide * {
    // stylelint-disable-next-line
    cursor: none !important;
  }

  &.has-lock-scroll,
  &.is-lock-scroll,
  &.has-lock-scroll body,
  &.is-lock-scroll body {
    overflow: hidden;
  }

  @include devices {
    font-size: 12px;

    line-height: 16px;
    //letter-spacing: 0.48px;
  }
}

body {
  //overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  text-decoration-skip: objects;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  color: $color-white;
  font-family: $font-Helvetica;

  #root {
    background: $color-black url('../../assets/images/backgrounds/bg.png') center center no-repeat;
    background-size: cover;
  }
}

#root {
  position: relative;
  //overflow-x: hidden;
}

main {
  flex: 1;
}

p {
  margin: 0;
}

a,
button {
  outline: none;
  transition: $trans-def;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: currentColor;
}

svg {
  display: block;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  fill: currentColor;
}

figure,
picture {
  display: inline-block;
  margin: 0;
  line-height: 0;

  img {
    width: 100%;
    transform: translateZ(0);
  }
}

img {
  vertical-align: top;
  max-width: 100%;
  transform: translateZ(0);
  user-select: none;
}

img[draggable="false"] {
  pointer-events: none;
  -webkit-user-drag: none;
}

fieldset {
  margin: 0;
  border: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

video {
  outline: none;
  width: 100%;
  height: 100%;
}

iframe {
  display: block;
}

.main {


  overflow: hidden;
}

.transition-def {
  transition: $trans-def;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.for-devices {
  display: none;
}

@include desktop {}

@include devices {
  .for-devices {
    display: block;
  }

  .for-desktop {
    display: none;
  }
}

.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: 0;
  padding: 0;
  text-align: center;
  color: $color-light;
  background-color: transparent;
  transition: $trans-def;
  user-select: none;

  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    color: currentColor;
    transform: translateZ(0);
  }
}

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  position: relative;
  z-index: 2;
  min-height: 100vh;
  backface-visibility: hidden;
  will-change: transform, opacity;
  transform: translateZ(0);
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
}

@include desktop {

  [data-scroll],
  [data-scroll-section] {
    backface-visibility: hidden;
    will-change: transform, opacity;
    transform: translateZ(0);
  }

  [data-scroll-container] {
    perspective: 999999px !important;
  }

  [data-scroll-container],
  [data-scroll-section] {
    transform-style: preserve-3d;
    will-change: transform;
  }
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  width: 11px;
  height: 100%;
  height: var(--vh);
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}

.c-scrollbar:hover {
  transform: scaleX(1.45);
}

.c-scrollbar:hover,
.has-scroll-scrolling .c-scrollbar,
.has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

[data-scroll-direction="horizontal"] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}

[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  right: auto;
  bottom: 0;
}

.block-color-1 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 202px;
  height: 202px;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.02);


}

.block-color-2 {
  position: absolute;
  left: 540px;
  top: 48px;
  pointer-events: none;
  width: 62px;
  height: 62px;
}

.block-color-3 {
  position: absolute;
  right: 19px;
  bottom: 0;
  pointer-events: none;
  width: 241.75px;
  height: 241.75px;
}

.block-color-4 {
  position: absolute;
  left: 475px;
  bottom: 0;
  pointer-events: none;
  width: 241.75px;
  height: 241.75px;
}

.block-color-5 {
  position: absolute;
  left: 240px;
  bottom: 89px;
  pointer-events: none;
  width: 62px;
  height: 62px;
}

.block-color-6 {
  position: absolute;
  left: 140px;
  top: 0;
  pointer-events: none;
  width: 62px;
  height: 62px;
}

.block-color-7 {
  position: absolute;
  left: 0px;
  top: 0;
  pointer-events: none;
  width: 62px;
  height: 62px;
}

.block-color-8 {
  position: absolute;
  right: 0px;
  bottom: 0;
  pointer-events: none;
  width: 62px;
  height: 62px;
}

.block-color-9 {
  position: absolute;
  left: 50px;
  top: 25px;
  pointer-events: none;
  width: 62px;
  height: 62px;
}

.block-color-10 {
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
  width: 62px;
  height: 62px;
}

.block-color-11 {
  position: absolute;
  left: 170px;
  top: 0;
  pointer-events: none;
  width: 208px;
  height: 208px;
}

.block-color-12 {
  position: absolute;
  left: 610px;
  bottom: 0;

  width: 177px;
  height: 177px;
  pointer-events: none;
}

.block-color-13 {
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
  width: 61px;
  height: 61px;
}


.block-color {
  &-14 {
    position: absolute;
    right: 0;
    top: 0;
    width: 132px;
    height: 100%;
  }

  &-15 {
    position: absolute;
    left: 55%;
    top: 68px;
  }

  &-16 {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 510px;
    height: 108.5px;
  }
}

.button {
  display: flex;
  padding: 16px 39px;
  justify-content: center;
  align-items: center;
  font-family: $font-minecraft;

  text-align: center;

  font-size: 19px;

  line-height: 20px;
  letter-spacing: 0.56px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &-color {
    color: $color-text;
    background: $color-theme;
    box-shadow: 8px 8px 0px 0px rgba(255, 255, 255, 0.04);

    &:hover {
      background: #00F0FF;
      box-shadow: 8px 8px 0px 0px rgba(255, 255, 255, 0.04);
    }

    @include devices {}
  }

  &-2 {
    padding: 16px 30px;

    @include devices {
      width: 100%;
      padding: 14px 30px;
    }
  }

  &-3 {
    padding: 16px 8px;
  }

  &-border {
    border: 1px solid $color-theme;
    color: $color-theme;

    &:hover {
      border: 1px solid #01E8DE;
    }
  }

  @include devices {
    position: relative;
    z-index: 1;
    font-size: 17px;
  }
}

.info-att {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  gap: 12px;
  background: rgba(255, 255, 255, 0.06);
  letter-spacing: 0.56px;

  img {
    width: 32px;
    height: 32px;

    @include devices {
      width: 16px;
      height: 16px;
    }
  }

  @include devices {

    padding: 8px;

    gap: 4px;
    font-weight: 700;
    letter-spacing: 0.48px;
  }
}

.gap-32 {
  gap: 32px;
}

.gap-16 {
  gap: 16px;
}

.gap-12 {
  gap: 12px;
}

.gap-20 {
  gap: 20px;
}

.mt-32 {
  margin-top: 32px;
}

@include desktop {

  .m-24 {
    margin: 24px;
  }

  .mt-24 {
    margin-top: 24px;
  }


  .mb-12 {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .mt-44 {
    margin-top: 44px;
  }

  .mt-65 {
    margin-top: 65px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

}

@include devices {
  .gap-8-m {
    gap: 8px;
  }

  .gap-12-m {
    gap: 12px;
  }

  .d-flex-m {
    display: flex;
    flex-flow: column;
  }
}

.title-neon {
  position: relative;
  z-index: 3;
  color: #FFF;
  margin-top: -62px;
  /* 1 */
  text-shadow: 0px 2px 38px rgba(1, 232, 222, 0.60), 0px 4px 2px rgba(0, 0, 0, 0.45);
  font-family: $font-minecraft;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: 1px;

  @include devices {
    margin-top: -32px;
    text-shadow: 0px 2px 38px rgba(1, 232, 222, 0.60), 0px 4px 2px rgba(0, 0, 0, 0.45);

    font-size: 28px;

    line-height: 32px;
    letter-spacing: 0.2px;
  }
}

.text-md {
  color: rgba(255, 255, 255, 0.60);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @include devices {
    font-size: 14px;
    line-height: 20px;
  }
}

.section {
  position: relative;
  margin-bottom: 20px;
  padding: 32px;
  background: rgba(255, 255, 255, 0.04);

  &--faq {
    @include desktop {
      padding-top: 16px;
    }

    @include devices {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  &-md {

    padding: 16px;
    background: rgba(255, 255, 255, 0.02);

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &-xs {
    padding: 10px 16px;
  }

  &-token {
    position: absolute;
    bottom: 0;
    right: 133px;
    width: 340.685px;
    height: 340px;
    pointer-events: none;


    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 70px;
      margin: 0 auto;
      bottom: 0;
      width: 80%;
      height: 80%;
      border-radius: 100px;
      background: linear-gradient(315deg, #59FFF8 27.63%, #7CFFF9 69.34%);

      filter: blur(71.9682388305664px);
    }

    &-inner {
      @include desktop {
        overflow: hidden;
      }
    }

    img {
      @include desktop {
        position: relative;
        top: 55px;
        z-index: 1;
      }
    }

    @include devices {

      bottom: 38px;
      right: 25px;
      width: 96.85px;
      height: 96.516px;
      transform: rotate(12.04deg);
    }
  }

  &-map {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 682px;

    @include devices {
      width: 296px;
      height: 95px;
      bottom: -23px;
      right: -6px;
      opacity: 0.24;
    }
  }

  &-uls {
    position: absolute;


    &::after {
      @include desktop {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 70px;
        margin: 0 auto;
        bottom: 0;
        width: 70%;
        height: 70%;
        border-radius: 100px;
        background: linear-gradient(315deg, #59FFF8 27.63%, #7CFFF9 69.34%);

        filter: blur(71.9682388305664px);
      }
    }

    &-inner {
      position: relative;
      z-index: 1;

      width: 100%;
      height: 100%;

      img {
        position: absolute;

        @include desktop {
          &:nth-child(1) {
            right: 0;
            top: 0px;
            width: 360.204px;
            height: 358.962px;


          }

          &:nth-child(2) {
            left: 0;
            bottom: -61px;
            width: 317.973px;
            height: 317.973px;
          }
        }

        @include devices {
          width: 42.212px;
          height: 42.067px;
          transform: rotate(28.697deg);

          &:nth-child(1) {
            left: 48%;
            top: -28px;
          }

          &:nth-child(2) {
            left: -9px;
            top: 14px;
          }
        }
      }

      @include desktop {
        overflow: hidden;
      }
    }

    @include desktop {
      right: 62px;
      width: 457px;
      height: 524px;

      bottom: -31px;
    }

    @include devices {
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &-solo {
    @include desktop {
      padding: 5px 32px;

      .title-neon {
        margin-top: -34px;
        margin-bottom: 15px;
      }
    }
  }

  @include devices {
    padding: 16px;

    &-solo {
      padding: 5px 16px;

      .title-neon {
        margin-top: -24px;
        margin-bottom: 3px;
      }
    }
  }
}

.block-1 {

  margin-top: 24px;
  margin-bottom: 38px;

  p {
    margin-bottom: 20px;
  }

  @include desktop {
    width: 560px;
  }

  @include devices {
    margin-top: 16px;
    margin-bottom: 24px;
  }
}

.block-2 {
  position: relative;
  z-index: 1;
  margin-top: 24px;
  //width: 369px;

  @include devices {
    margin-top: 15px;

    br {
      display: none;
    }
  }
}

.block-3 {

  max-width: 476px;
}

.block-4 {
  max-width: 800px;
}

.title-md {

  font-size: 16px;

  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;

  @include devices {

    font-size: 14px;

    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.56px;
    text-transform: uppercase;

    &-2 {
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }
}

.text-gray {
  color: rgba(255, 255, 255, 0.60);
}

.text-bold {
  //padding-left: 4px;
  font-weight: 700;
  letter-spacing: 0.56px;
  text-transform: uppercase;
}

.text-select {
  margin-top: 4px;
  color: $color-theme;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-bottom: 1px solid $color-theme;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-bottom-color: transparent;
  }
}

.text-theme {
  color: $color-theme;

  &:hover {
    color: #00F0FF;
    text-decoration: none;
  }
}

.column-2 {
  columns: 2 auto;
}

.column-3 {
  columns: 3 auto;
}

.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
}

.footer {
  margin-top: 40px;
  padding: 40px;
  background: $color-black;
  color: $color-white;

  &-title {
    margin-bottom: 16px;
    color: #FFF;
    text-align: left;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33);
    font-family: $font-minecraft;
    font-size: 16px;

    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.48px;
    text-transform: uppercase;

    @include devices {
      margin-bottom: 8px;
    }
  }

  &-list {
    display: flex;
    flex-flow: column;
    gap: 8px;

    li {}

    a {
      color: rgba(255, 255, 255, 0.60);

      font-size: 14px;

      font-weight: 400;
      line-height: 20px;
      /* 142.857% */

      &:hover {
        color: #00F0FF;
      }

      @include devices {
        color: rgba(255, 255, 255, 0.80);

        font-size: 12px;

        line-height: 16px;
      }
    }

    @include devices {
      gap: 6px;
    }
  }

  &-info {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;

    img {
      width: 40px;
      height: 40px;
    }
  }

  &-price {
    display: flex;
    flex-flow: column;
    color: #FFF;
    font-family: $font-minecraft;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    text-transform: uppercase;

    span {
      color: rgba(255, 255, 255, 0.60);
      font-family: $font-Helvetica;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      /* 114.286% */
    }
  }

  &-bottom {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }

  .copyright {
    color: rgba(255, 255, 255, 0.60);

    font-size: 14px;

    line-height: 20px;

    @include devices {

      font-size: 12px;

      line-height: 16px;
    }
  }

  @include devices {
    margin: 40px -16px 0;
    padding: 25px 15px;
  }
}

.socials {
  .social {
    width: 20px;
    height: 20px;
  }
}

.pl-4 {
  padding-left: 4px;
}

.recharts-default-tooltip {
  border: 1px solid var(--6, #01E8DE) !important;
  background: #1A1A1B !important;

  display: inline-flex;
  padding: 8px !important;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.recharts-tooltip-label {
  color: #FFF;

  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33);
  font-family: $font-minecraft;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: 0.48px;
  text-transform: uppercase;

  @include devices {
    color: #FFF;

    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33);

    font-size: 18px;

    line-height: 20px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }
}

.recharts-tooltip-item-name,
.recharts-tooltip-item-separator {

  color: rgba(255, 255, 255, 0.60);
  font-family: $font-Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */

  @include devices {

    font-size: 12px;

    line-height: 16px;
  }
}

.recharts-tooltip-item-value {
  color: #FFF;
  display: block;
  font-family: $font-Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.56px;
  text-transform: uppercase;

  @include devices {

    font-size: 12px;

    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }
}

.recharts-active-dot {
  //stroke-dasharray: 30px 30px;
  fill: #1A1A1B;

  .recharts-dot {

    border-radius: 0;
    width: 16px;
    height: 16px;
    stroke: #01E8DE;
    //cx: 423.75;
    //cy: 58.9231;
    r: 8;
    fill: #1A1A1B;
    stroke-width: 1;
  }

  //
  //stroke-dasharray: 10px 10px;
  //stroke: rgba(#01E8DE, 0.2) !important;
}

.recharts-cartesian-axis-line[orientation="left"] {
  opacity: 0;
}

.recharts-cartesian-axis-tick-line {
  opacity: 0;
}

.recharts-tooltip-cursor {
  stroke-dasharray: 10px 10px;
  stroke: rgba(#01E8DE, 0.2) !important;
}

.font-minecraft {
  font-family: $font-minecraft;
}

.mb-24 {
  margin-bottom: 24px;

  @include devices {
    margin-bottom: 16px;
  }
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-4 {
  margin-bottom: 4px !important;
}


.text-p {
  max-width: 932px;
  word-break: break-word;

  p:not(:last-child) {
    margin-bottom: 15px;
  }

  ul {
    margin-bottom: 15px;
    padding-left: 20px;

    li {
      list-style: disc;
      color: rgba(255, 255, 255, 0.80);


      @include devices {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }


  }

  a {
    color: $color-theme;
    border-bottom: 1px solid $color-theme;

    &:hover {
      border-bottom-color: transparent;
    }
  }
}

.box {
  background: rgba(255, 255, 255, 0.04);
}

.p-32 {
  padding: 32px;
}

.mt-20 {
  margin-top: 20px;
}

.roadMap {
  position: relative;
  display: flex;
  flex-flow: column;
  gap: 20px;

  &::before {
    content: "";
    position: absolute;
    top: 7px;
    left: 6.5px;
    width: 1px;
    height: calc(100% - 25px);
    background: $color-theme;

    @include devices {
      left: 6px;
    }
  }

  li {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;

    color: #FFF;

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;

    &::before {
      content: "";
      display: block;
      flex-shrink: 0;
      margin-right: 16px;
      border: 1px solid #01E8DE;
      background: #1A1A1B;
      width: 13px;
      height: 13px;
    }

    a,
    span {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-left: 8px;
      color: rgba(255, 255, 255, 0.60);

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-transform: none;

      &::before {
        display: block;
        content: "|";
        color: #01E8DE;
        font-family: Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 150% */
        text-transform: uppercase;
      }
    }

    @include devices {
      font-size: 14px;
      line-height: 20px;
    }
  }

  @include devices {
    gap: 24px;
    margin-bottom: 24px;
  }
}

.tokenomics-chart {
  //height: 1200px !important;
}

.icon-link {
  display: flex;
  width: 44px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  background: $color-white;

  box-shadow: 8px 8px 0px 0px rgba(255, 255, 255, 0.04);

  img,
  svg {
    width: 24px;
    height: 24px;
  }
}

.text-link {
  color: $color-theme;
  text-decoration: underline;
  word-wrap: break-word;
}

.text-right {
  text-align: right;
}

.text-gray-2 {
  color: rgba(255, 255, 255, 0.80);

}

.text-decorate-underline {
  text-decoration: underline;
  word-wrap: break-word;
}

.icon-token {
  display: inline-block;
  width: 20px;
  height: 20px;

  @include devices {
    margin-left: 5px;
    width: 16px;
    height: 16px;
  }
}

.info-connect {
  position: relative;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 12px;
  background: rgba(255, 255, 255, 0.06);

  &-content {}

  &-title {
    color: rgba(255, 255, 255, 0.80);

    font-size: 12px;

    font-weight: 400;
    line-height: 16px;

    @include devices {}
  }

  &-text {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #FFF;

    font-size: 12px;

    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.48px;
    text-transform: uppercase;


  }

  &-arrow {
    width: 16px;
    height: 16px;
    background: url('../../assets/images/icons/arrow-down.svg') center center no-repeat;
    cursor: pointer;

    &.is-open {
      transform: rotate(180deg);
    }

    @include devices {
      width: 12px;
      height: 12px;
    }
  }

  @include devices {

    padding: 8px;

    gap: 4px;
  }
}

.popup {
  position: absolute;
  top: 110%;
  right: 0;
  z-index: 5;
  display: flex;
  width: 200px;
  padding: 16px;
  flex-direction: column;

  border: 1px solid #01E8DE;
  background: #1A1A1B;

  &-title {
    margin-bottom: 12px;
    color: #FFF;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33);
    font-family: $font-minecraft;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }

  &-number {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #FFF;

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.56px;
    text-transform: uppercase;
  }

  &-price {
    margin-top: 4px;
    margin-bottom: 12px;
    color: rgba(255, 255, 255, 0.80);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.info-people {
  position: relative;

  &-img {

    width: 32px;
    height: 32px;

    @include devices {

      width: 35px;
      height: 35px;

      img {}
    }
  }

  &-name {
    color: #FFF;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.56px;
    text-transform: uppercase;

    @include devices {
      position: absolute;
      top: 115%;
      right: 0;
      display: inline-flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      min-width: 184px;
      border: 1px solid #01E8DE;
      background: #1A1A1B;
      white-space: nowrap;
      opacity: 0;
      visibility: hidden;
    }
  }

  @include desktop {
    display: flex;
    padding: 10px 16px;
    align-items: center;
    gap: 12px;
    background: rgba(255, 255, 255, 0.06);
  }

  @include devices {
    border: 1px solid transparent;
    width: 35px;
    height: 35px;

    &:hover {
      border-color: #01E8DE;

      .info-people-name {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.active {
  color: #01E8DE !important;
}

.mb-40 {
  margin-bottom: 40px;
}

@include desktop {
  .position-relative-d {
    position: relative;
  }
}

@include devices {
  .text-small-m {


    font-size: 14px;
    letter-spacing: 0.56px;
    line-height: 20px;
  }


  .text-xsmall-m {

    font-size: 14px;

    line-height: 20px;
  }

  .mb-4-m {
    margin-bottom: 4px;
  }

  .mb-16-m {
    margin-bottom: 16px;
  }

  .recharts-responsive-container {
    margin: 0 -24px;
    width: 115% !important;

    &.tokenomics-chart {
      height: 300px !important;
    }
  }

  .recharts-tooltip-item {
    padding-top: 0 !important;
  }

  .mt-36-m {
    margin-top: 36px;
  }

  .gap-8-m {
    gap: 8px;
  }

  .gap-4-m {
    gap: 4px;
  }

  .p-24-m {
    padding: 24px;
  }

  .mb-8-m {
    margin-bottom: 8px;
  }

  .years-outer {
    overflow-x: auto;
  }

  .years {
    padding-bottom: 30px;
    width: 250vw;

    &-nav {

      bottom: 10px;
      position: absolute;
      width: 24px;
      height: 24px;

      &-left {
        left: 8px;
      }

      &-right {
        right: 8px;
      }
    }
  }

}