@font-face {
  font-family: "Minecraft";
  src:
    url("../../../assets/fonts/Minecraft/289-font.woff2"),
    url("../../../assets/fonts/Minecraft/289-font.woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src:
    url("../../../assets/fonts/Helvetica/helvetica_regular.woff2"),
    url("../../../assets/fonts/Helvetica/helvetica_regular.woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src:
    url("../../../assets/fonts/Helvetica/helvetica_bold.woff2"),
    url("../../../assets/fonts/Helvetica/helvetica_bold.woff");
  font-weight: 700;
  font-display: swap;
}