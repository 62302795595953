.accordion {
  --bs-accordion-bg: transparent;
  border: none;

  --bs-accordion-border-width: 0;
  --bs-accordion-btn-padding-x: 0;
  --bs-accordion-body-padding-x: 0;
  --bs-body-color: rgba(255, 255, 255, 0.80);

  --bs-accordion-btn-focus-border-color: transparent;
  --bs-accordion-btn-focus-box-shadow: transparent;
  --bs-accordion-btn-icon: url('../../assets/images/icons/arrow-down.svg');
  --bs-accordion-btn-active-icon: url('../../assets/images/icons/arrow-down.svg');
  --bs-accordion-btn-icon-width: 24px;
  --bs-accordion-btn-icon-height: 24px;
}

.accordion-button,
.accordion-button:not(.collapsed) {
  background: transparent;

  color: #FFF;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  text-transform: uppercase;
  outline: none;
}

.accordion-item {
  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }
}