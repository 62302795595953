.main {
  @media screen and (min-width: 1024px) {
    display: flex;
    gap: 40px;
    margin: 0 auto;
    max-width: 1366px;
  }

  @media screen and (max-width: 1024px) {
    padding: 0 16px;
    overflow: hidden;
  }
}

.chart {
  height: 252px;

  @media screen and (max-width: 1024px) {

    height: 249px;
    padding-bottom: 40px;


  }
}

.navigationTop {
  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
  }
}

.navigation {
  &__inner {
    @media screen and (min-width: 1024px) {
      display: flex;
      flex-flow: column;
      align-items: center;
      position: -webkit-sticky;
      /* Safari */
      position: sticky;
      top: 0;
      padding-top: 34px;
      align-self: flex-start;

      height: 100vh;
      flex-shrink: 0;
      background: rgba(255, 255, 255, 0.02);
    }
  }

  &__logo {
    display: block;

    @media screen and (min-width: 1024px) {
      width: 58px;
      margin-bottom: 73px;

    }

    @media screen and (max-width: 1024px) {
      flex-shrink: 0;
    }
  }

  ul {

    a {
      display: flex;
      align-items: center;
      gap: 12px;
      color: rgba(255, 255, 255, 0.80);

      svg {
        width: 24px;
        height: 24px;

      }


      @media screen and (max-width: 1024px) {
        gap: 8px;


        font-size: 16px;

        line-height: 24px;
      }
    }


    @media screen and (min-width: 1024px) {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;

    }

    @media screen and (max-width: 1024px) {
      display: flex;
      gap: 36px;
      overflow-x: auto;
      padding: 22px 0 20px;
      margin-bottom: 20px;
    }
  }

  @media screen and (min-width: 1024px) {
    flex-shrink: 0;
    width: 202px;
  }
}

.sectionToken {}


.input {
  margin-top: 26px;

  &__control {
    position: relative;
    height: 52px;

    input {
      display: flex;
      padding: 10px 16px 10px 12px;
      align-items: center;
      width: 100%;
      height: 100%;
      text-align: right;

      font-size: 16px;
      color: #fff;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.64px;
      text-transform: uppercase;
      border: 1px solid rgba(255, 255, 255, 0.16);
      background: rgba(255, 255, 255, 0.02);

      outline: none;
    }
  }

  &__icon {
    position: absolute;
    left: 12px;
    top: 10px;
    display: flex;
    align-items: center;
    gap: 12px;
    color: rgba(255, 255, 255, 0.60);

    font-size: 16px;

    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.64px;
    text-transform: uppercase;

    img {
      width: 32px;
      height: 32px;
    }
  }
}