.filter {

  &-item {
    display: block;
    padding: 10px 0;
    background: rgba(255, 255, 255, 0.20);
    color: rgba(255, 255, 255, 0.60);
    text-align: center;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33);

    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    text-transform: uppercase;
    letter-spacing: 0.48px;
    width: 100%;
    border: none;

    &.is-active,
    &.active {
      font-size: 19px;
      background: #01E8DE;
      color: #013A3D !important;
      box-shadow: 8px 8px 0px 0px rgba(255, 255, 255, 0.04);
      letter-spacing: 0.56px;
      //pointer-events: none;

      @media screen and (max-width: 1024px) {
        font-size: 16px;

        line-height: 16px;
        letter-spacing: 0.48px;
      }
    }

    &:hover {
      background: #00F0FF;
      color: #013A3D !important;
      box-shadow: 8px 8px 0px 0px rgba(255, 255, 255, 0.04);
    }

    @media screen and (max-width: 1024px) {
      padding: 10px 16px;

      font-size: 16px;

      line-height: 16px;
      letter-spacing: 0.48px;

    }
  }

  @media screen and (max-width: 1024px) {
    .row {
      margin: -8px -10px;
      padding-bottom: 20px;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      gap: 4px;

      .col-md-3,
      .col-md-2 {
        padding: 0;
        width: auto;
      }
    }
  }
}

.cards {

  &-item {
    display: flex;
    justify-content: space-between;
    padding: 32px;
    height: 220px;

    background: rgba(255, 255, 255, 0.04);

    &-content {
      width: 197px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    }

    &-title {
      color: #FFF;

      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      /* 142.857% */
      letter-spacing: 0.56px;
      text-transform: uppercase;
    }

    &-text {
      margin: 12px 0 24px;
      color: rgba(255, 255, 255, 0.60);

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 142.857% */

      @media screen and (max-width: 1024px) {
        margin: 12px 0 15px;
      }
    }

    &-link {
      display: flex;
      width: 136px;
      padding: 10px 0px;
      border: 1px solid var(--6, #01E8DE);
      justify-content: center;
      align-items: center;
      color: #01E8DE;
      text-align: center;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 166.667% */
      letter-spacing: 0.48px;
      text-transform: uppercase;

      @media screen and (max-width: 1024px) {
        width: 180px;
      }
    }

    &-image {
      display: flex;
      width: 60px;
      height: 60px;

      justify-content: center;
      align-items: center;
      flex-shrink: 0;


      img {
        width: 100%;
        height: 100%;
        box-shadow: 8px 8px 0px 0px rgba(255, 255, 255, 0.04);

      }
    }

    @media screen and (max-width: 1024px) {
      padding: 16px;
      height: auto;
    }
  }
}