$font-weight-base: 400;
$font-minecraft: 'Minecraft', sans-serif;
$font-Helvetica: 'Helvetica', sans-serif;
$desktop: 1025;
$desktop-xl: 1800;
$laptop: 1440;
$tablet: 767;
$mobile: 320;
$desktop-vh: 750;

$trans-def: 0.3s ease-in-out;
$trans-slow: 0.8s ease-out;
$trans-fast: 0.15s ease-out;